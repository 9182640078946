/**
 * C8-mobile-web-app
 * C8-mobile-web-app api
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type Language = 'en' | 'du' | 'fr';

export const Language = {
    En: 'en' as Language,
    Du: 'du' as Language,
    Fr: 'fr' as Language
};

