/**
 * C8-mobile-web-app
 * C8-mobile-web-app api
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type Role = 'main' | 'admin' | 'member';

export const Role = {
    Main: 'main' as Role,
    Admin: 'admin' as Role,
    Member: 'member' as Role
};

