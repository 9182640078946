import { Component, OnInit, Renderer2 } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { HelpersService } from './services/helpers.service';
import { Router, RoutesRecognized } from '@angular/router';
import { TranslatePipe } from './utils/pipes';
import { fromEvent, Observable, Subscription } from 'rxjs';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    standalone: false
})
export class AppComponent implements OnInit {
    ready: boolean = false;
    fetchedInitialRouteData: boolean = false;
    isModalRoute: boolean = false;

    // network
    onlineEvent: Observable<Event>;
    offlineEvent: Observable<Event>;

    subscriptions: Subscription[] = [];

    connectionStatusMessage: string;
    connectionStatus: string;

    constructor(
        private TitleService: Title,
        private MetaService: Meta,
        public HelpersService: HelpersService,
        public Router: Router,
        public TranslatePipe: TranslatePipe,
        private Renderer: Renderer2
    ) {}

    ngOnInit(): void {
        // // listen to router events
        // this.Router.events.subscribe((event) => {
        //     if (event instanceof RoutesRecognized) {
        //         let route = event.state.root.firstChild;
        //         if (this.validateRoute(route)) {
        //             this.handleRouteChange(route);
        //         }
        //     }
        // });
        // // listen to modal route config
        // this.ConfigService.getModalRoute().subscribe((next) => {
        //     setTimeout(() => {
        //         this.isModalRoute = next;
        //     }, 1);
        // });
        // // ready
        this.ready = true;

        /**
         * Get the online/offline status from browser window
         */
        this.onlineEvent = fromEvent(window, 'online');
        this.offlineEvent = fromEvent(window, 'offline');

        this.subscriptions.push(
            this.onlineEvent.subscribe((e) => {
                this.connectionStatusMessage = 'Network connection restored';
                this.connectionStatus = 'online';
                // console.log('Online...');
            })
        );

        this.subscriptions.push(
            this.offlineEvent.subscribe((e) => {
                this.connectionStatusMessage = 'Network connection lost';
                this.connectionStatus = 'offline';
                // console.log('Offline...');
            })
        );
    }
    public handleRouteChange(route) {
        // let title = this.TranslatePipe.transform('meta_title');
        // let description = this.TranslatePipe.transform('meta_description');
        // let robots = 'index, follow';
        // let routeTitle = route?.data?.title;
        // if (route?.firstChild?.data?.title) routeTitle = route.firstChild.data.title;
        // // title
        // if (routeTitle) {
        //     let suffix = '';
        //     if (route?.params?.usage) {
        //         suffix += `_${this.RoutingService.getSlugKey(route?.params?.usage)}`;
        //     }
        //     if (route?.params?.list && this.RoutingService.getSlugKey(route?.params?.list)) {
        //         const key = this.RoutingService.getSlugKey(route?.params?.list);
        //         const tile = this.ResourceService.getTile(key);
        //         suffix += `_${tile.code}`;
        //     }
        //     const pre = this.TranslatePipe.transform(`meta_title_${routeTitle}${suffix}`);
        //     title = `${pre} | ${title}`;
        // }
        // // robots
        // if (route?.data?.noIndex) {
        //     robots = 'noindex';
        // }
        // // hide header
        // if (route?.data?.hideHeader) {
        //     this.ConfigService.setHideHeader(true);
        // } else this.ConfigService.setHideHeader(false);
        // // hide footer
        // if (route?.data?.hideFooter) {
        //     this.ConfigService.setHideFooter(true);
        // } else this.ConfigService.setHideFooter(false);
        // // handle modal routes
        // if (route?.firstChild?.data?.modalRoute) {
        //     this.Renderer.addClass(document.body, 'modal-open');
        //     this.Renderer.addClass(document.body, 'modal-open--route');
        //     this.ConfigService.setModalRoute(true);
        // } else if (document.body.classList.contains('modal-open--route')) {
        //     this.ConfigService.setModalRoute(false);
        //     this.Renderer.removeClass(document.body, 'modal-open');
        //     this.Renderer.removeClass(document.body, 'modal-open--route');
        // }
        // // hide nav
        // if (route?.data?.hideNav) {
        //     this.ConfigService.setHideNav(true);
        // } else this.ConfigService.setHideNav(false);
        // // infoPage
        // if (route?.data?.infoPage) {
        //     this.ConfigService.setInfoPageActive(true);
        // } else this.ConfigService.setInfoPageActive(false);
        // // usage
        // if (route?.params?.usage) {
        //     this.ConfigService.setUsage(this.RoutingService.getSlugKey(route?.params?.usage));
        // }
        // if (routeTitle !== 'async') this.TitleService.setTitle(title);
        // this.MetaService.addTag({
        //     name: 'description',
        //     content: description
        // });
        // this.MetaService.addTag({
        //     name: 'robots',
        //     content: robots
        // });
    }

    ngOnDestroy(): void {
        /**
         * Unsubscribe all subscriptions to avoid memory leak
         */
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }
}
