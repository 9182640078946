/**
 * C8-mobile-web-app
 * C8-mobile-web-app api
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PlanningPromoAvailabilityAvailabilities } from './planningPromoAvailabilityAvailabilities';


export interface PlanningChangeAvailabilityBody { 
    availabilities: Array<PlanningPromoAvailabilityAvailabilities>;
}

