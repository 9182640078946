import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { TranslatorService } from '../services/translator.service';
import { TranslatePipe } from '../utils/pipes';
import * as packageJson from '../../../../package.json';
import { Title } from '@angular/platform-browser';
@Injectable()
export class ApiInterceptor implements HttpInterceptor {
    constructor(
        private router: Router,
        private TranslatorService: TranslatorService,
        private TranslatePipe: TranslatePipe,
        private TitleService: Title
    ) {}

    public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let clonedRequest = request.clone({
            headers: request.headers.append('Accept-Language', this.TranslatorService.getLanguageValue() || 'nl')
        });
        const packageJsonAny: any = packageJson;
        if (clonedRequest.method == 'GET') {
            const versionParam = `${packageJsonAny.version}-${this.TranslatorService.getLanguageValue() || 'nl'}`;
            clonedRequest = request.clone({
                setParams: {
                    v: versionParam
                },
                headers: request.headers.append('Accept-Language', this.TranslatorService.getLanguageValue() || 'nl')
            });
        }
        // function failwhale(error) {
        //     const msg = `${error.error}`;
        //     if (document.getElementsByClassName('page') && document.getElementsByClassName('page').length) {
        //         document.getElementsByClassName('page')[0].innerHTML = msg;
        //     } else {
        //         document.getElementsByTagName('BODY')[0].innerHTML = msg;
        //     }
        //     // modals
        //     if (
        //         document.getElementsByClassName('modal-backdrop') &&
        //         document.getElementsByClassName('modal-backdrop').length
        //     ) {
        //         document.getElementsByClassName('modal-backdrop')[0].setAttribute('style', 'display:none !important');
        //     }
        //     if (document.getElementsByClassName('modal') && document.getElementsByClassName('modal').length) {
        //         document.getElementsByClassName('modal')[0].setAttribute('style', 'display:none !important');
        //     }
        //     if (document.getElementsByClassName('modal-open') && document.getElementsByClassName('modal-open').length) {
        //         document.getElementsByClassName('modal-open')[0].setAttribute('style', 'overflow:unset !important');
        //     }
        //     // footer
        //     if (document.getElementsByClassName('newsletter') && document.getElementsByClassName('newsletter').length) {
        //         document.getElementsByClassName('newsletter')[0].setAttribute('style', 'display:none !important');
        //     }
        //     if (document.getElementsByClassName('footer') && document.getElementsByClassName('footer').length) {
        //         document.getElementsByClassName('footer')[0].setAttribute('style', 'display:none !important');
        //     }
        // }
        return next.handle(clonedRequest).pipe(
            catchError((error: HttpErrorResponse) => {
                switch (error.status) {
                    case 400:
                        // bad request -> validation
                        break;
                    case 504:
                        break;
                    case 401:
                        // unauthorized
                        break;
                    case 500:
                        // internal server error
                        // failwhale(error);
                        break;
                    case 403:
                        // forbidden
                        // failwhale(error);
                        break;
                    case 404:
                        // not found
                        // failwhale(error);
                        break;
                }
                return throwError(error);
            })
        );
    }
}
