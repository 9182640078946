import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, importProvidersFrom, inject, provideAppInitializer } from '@angular/core';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { ApiInterceptor } from './interceptors/api.interceptor';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ApiModule, Configuration, ConfigurationParameters } from '../../../shared/api/';
import { ClickOutsideDirective } from './utils/directives';
import {
    displayPipe,
    listPipe,
    callbackPipe,
    centPipe,
    objLenPipe,
    formatNumberPipe,
    httpsPipe,
    SafePipe,
    toFixedPipe,
    vatPipe,
    formatTsPipe,
    customCurrencyPipe,
    percentagePipe,
    TranslatePipe,
    numberPipe
} from './utils/pipes';
import { CommonModule, CurrencyPipe } from '@angular/common';
import { SpinnerComponent } from './components/misc/spinner/spinner.component';
import { InitService } from './services/init.service';
import { PlanningComponent } from './pages/planning/planning.component';
import { OnlineStatusComponent } from './components/misc/online-status/online-status.component';
import { JobComponent } from './components/layout/job/job.component';
import { NgxPullToRefreshComponent } from 'ngx-pull-to-refresh';



@NgModule({
    declarations: [
        AppComponent,
        ClickOutsideDirective,
        formatTsPipe,
        displayPipe,
        listPipe,
        callbackPipe,
        centPipe,
        objLenPipe,
        formatNumberPipe,
        httpsPipe,
        SafePipe,
        toFixedPipe,
        vatPipe,
        TranslatePipe,
        customCurrencyPipe,
        percentagePipe,
        numberPipe,
        SpinnerComponent,
        PlanningComponent,
        OnlineStatusComponent,
        JobComponent
    ],
    bootstrap: [AppComponent],
    imports: [
        BrowserAnimationsModule,
        BrowserModule,
        CommonModule,
        AppRoutingModule,
        NgbModule,
        NgxMaskDirective,
        NgxMaskPipe,
        FormsModule,
        ReactiveFormsModule,
        ApiModule.forRoot(() => {
            return new Configuration({ basePath: '/api' });
        }),
        NgxPullToRefreshComponent
    ],
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: ApiInterceptor, multi: true },
        provideAppInitializer(() => {
            const initializerFn = initializeApp(inject(InitService));
            return initializerFn();
        }),
        CurrencyPipe,
        customCurrencyPipe,
        percentagePipe,
        TranslatePipe,
        numberPipe,
        formatTsPipe,
        provideHttpClient(withInterceptorsFromDi()),
        provideNgxMask()
    ]
})
export class AppModule {}

export function initializeApp(InitService: InitService) {
    return (): Promise<any> => {
        return InitService.initialize();
    };
}
