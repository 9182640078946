/**
 * C8-mobile-web-app
 * C8-mobile-web-app api
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Role } from './role';
import { Language } from './language';


export interface User { 
    USERID: number;
    LANGUAGE: Language;
    ROLEWEIGHT: number;
    DOMAIN: string;
    ROLE: string;
    EMAIL: string;
    NAME: string;
    PORTALURL: string;
    DATASOURCE: string;
    ISADMIN?: boolean;
    roles?: Array<Role>;
}

