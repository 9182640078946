/**
 * C8-mobile-web-app
 * C8-mobile-web-app api
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PlanningPromoAvailabilityUnavailable } from './planningPromoAvailabilityUnavailable';


export interface PlanningPromoAvailabilityAvailabilities { 
    date: string;
    available?: boolean;
    unavailable: Array<PlanningPromoAvailabilityUnavailable>;
}

