
<div class="planning" [class.keyboard-active]="keyboardActive || keyboardActiveTimeout" [class.select-dates]="selectDatesActive" [class.selected-dates]="selectedDays | objLen" *ngIf="ready">
<!-- {{temp | json}} -->
    <div class="head-wrap">
        <div class="select-date">
            <button class="prev" [class.hidden]="!allowPrevMonth" (click)="prev()">
                <i></i>
            </button>
            <div>{{currentMonth | formatTs: 'MMMM YYYY'}}</div>
            <button class="next" (click)="next()">
                <i></i>
            </button>
        </div>
        <button class="info-button" *ngIf="!isControlcenter" (click)="expandLegend()"><i></i></button>
    </div>

    <ngx-pull-to-refresh
	spinnerColor="#E42834"
    spinnerSize="24"
	distanceForRefresh="40"
	[isEnable]="!(jobDetailExpanded || disablePullToRefresh || submitting)"
	(refresh)="reloadPlanning(null, $event);"
	style="display:block;height:100%;">

    <div class="calendar-wrap">
        <div class="weekDays">
            <div class="weekDay" [class.weekend]="$i == 5 || $i == 6" *ngFor="let day of weekDays; index as $i">{{day}}</div>
        </div>
        <div class="days" [class.no-pointer]="loadingPlanning" (touchstart)="swipe($event, 'start')" (touchend)="swipe($event, 'end')">
            <!-- offset month -->
            <div class="day day--offset" *ngFor="let day of currentMonthDayOffset"></div>
            <!-- days in this month -->
            <ng-container *ngFor="let day of currentMonthDays">
                <div class="day day--calendar" 
                    (click)="clickDay(day.date)" 
                    [class.day--available]="day.available === true"
                    [class.day--unavailable]="day.available === false"
                    [class.day--mixed]="day.unavailable?.length"
                    [class.day--selected]="selectedDay.date === day.date || selectedDays[day.date]" 
                    [class.day--jobs-open]="day.actionsOpen?.length" 
                    [class.day--jobs-applied]="day.actionsApplied?.length" 
                    [class.day--jobs-to-accept]="day.actionsToAccept?.length" 
                    [class.day--jobs-accepted]="day.actionsAccepted?.length" 
                    [class.day--is-past]="day.isPast" 
                    [attr.data-date]="day.date | formatTs: 'YYYY-MM-DD'"
                    [attr.data-is-past]="day.isPast">
                    <div class="day--inner">
                        <ng-container *ngFor="let range of day?.unavailable">
                            <div class="range" *ngIf="range.percentageOfDay" style="--pLength:{{range.percentageOfDay}};--rotate:{{range.rotateAmount}}deg"></div>
                        </ng-container>
                        <span class="date">{{day.date | formatTs: 'D'}}</span>
                        <span *ngIf="day.actionsToAccept?.length" class="notif--jobs-to-accept">{{day.actionsToAccept.length}}</span>
                        <span *ngIf="day.actionsOpen?.length && !day.actionsToAccept?.length" class="notif--jobs-open">{{(day.actionsOpen?.length || 0)}}</span>
                        <!-- <span *ngIf="(day.actionsOpen?.length || day.actionsApplied?.length) && !day.actionsToAccept?.length" class="notif--jobs-open">{{(day.actionsOpen?.length || 0) + (day.actionsApplied?.length || 0)}}</span> -->
                    </div>
                </div>
            </ng-container>
        </div>
    </div>

    </ngx-pull-to-refresh>

    <div class="slideout-wrap" (touchstart)="touchSlideout($event, 'start')" (touchend)="touchSlideout($event, 'end')">
        <div class="slideout" [class.slideout--expanded]="slideoutExpanded">
            <!-- default view -->
            <ng-container *ngIf="!selectDatesActive">
                <div class="slideout-head">
                    <div><button *ngIf="!slideoutExpanded && !(selectedDays | objLen) && slideoutTab === 'availability' && !selectedDay.isPast" class="select-dates" (click)="selectDates()">+ <span>{{'planning_select_days' | translate}}</span></button></div>
                    <div *ngIf="!(selectedDays | objLen)"><div class="title">{{selectedDay.date | formatTs: 'D MMMM YYYY'}}</div></div>
                    <div *ngIf="(selectedDays | objLen)"><div class="title">{{selectedDays | objLen}} {{'planning_days_selected' | translate}} <i (click)="cancelSelectDates()"></i></div></div>
                    <div><button class="slideout-toggle"><i (click)="slideoutExpanded = !slideoutExpanded"></i></button></div>
                </div>
                <div class="slideout-switch">
                    <div class="option-wrap first" *ngIf="!isMerch"><div class="option" (click)="changetab('availability')" [class.active]="slideoutTab === 'availability'">{{'planning_tab_availability' | translate}}</div></div>
                    <div class="option-wrap"><div class="option" [class.saving]="selectDatesActive || (selectedDays | objLen)" (click)="changetab('jobs')" [class.active]="slideoutTab === 'jobs'"><span class="position-relative">{{'planning_tab_jobs_to_accept' | translate}} <ng-container *ngIf="selectedDay.actionsToAccept?.length"><span class="notif--jobs-to-accept">{{selectedDay.actionsToAccept.length}}</span></ng-container></span></div></div>
                    <div class="option-wrap last" *ngIf="!isMerch"><div class="option" [class.saving]="selectDatesActive || (selectedDays | objLen)" (click)="changetab('open')" [class.active]="slideoutTab === 'open'"><span class="position-relative">{{'planning_tab_jobs_open' | translate}} <ng-container *ngIf="selectedDay.actionsOpen?.length"><span class="notif--jobs-open">{{(selectedDay.actionsOpen?.length || 0)}}</span></ng-container></span></div></div>
                </div>
                <!-- AVAILABILITY -->
                <ng-container *ngIf="slideoutTab === 'availability'">
                    <div class="slideout-content">
                        <!-- unpopulated | edit -->
                        <ng-container>
                            <ng-container *ngIf="(selectedDay.available === undefined && !selectedDay?.unavailable?.length && !(selectedDays | objLen)) || ((selectedDays | objLen) && !selectedDaysStatus()) || (editSelectedDayActive || (selectedDays | objLen))">
                                <!-- set -->
                                <div class="slideout-panel slideout-panel--availability--set" *ngIf="!unavailabilityFormActive && ((selectedDays | objLen) || !selectedDay.isPast)">
                                    <div class="title" *ngIf="!((selectedDays | objLen))">{{'planning_tab_availability_title' | translate}}</div>
                                    <div class="title" *ngIf="((selectedDays | objLen))">{{'planning_tab_availability_title_multiple_days' | translate}}</div>
                                    <div class="app-button app-button--pos" (click)="setAvailable()" [class.no-pointer]="submitting" [class.saving-this]="submitting === 'availability'"><button>{{'planning_tab_availability_button_available' | translate}}</button></div>
                                    <div class="app-button app-button--mixed" [class.no-pointer]="submitting" [class.saving-this]="submitting === ''"><button (click)="setUnavailabilityFormActive()">{{'planning_tab_availablilty_button_mixed' | translate}}</button></div>
                                    <div class="app-button app-button--neg" (click)="setUnavailable()" [class.no-pointer]="submitting" [class.saving-this]="submitting === 'unavailability'"><button>{{'planning_tab_availability_button_not_available' | translate}}</button></div>
                                    <!-- <div class="info" *ngIf="(selectedDays | objLen)">{{'planning_tab_availability_warning_multiple_days' | translate}}</div> --> <!-- @feedback -->
                                    <!-- warning multiple days -->
                                    <div class="warning" *ngIf="(selectedDays | objLen)">{{'planning_tab_availability_warning_multiple_days' | translate}}</div>
                                    <button class="cancel-button" *ngIf="editSelectedDayActive && !(selectedDays | objLen)" (click)="editSelectedDayActive = false; slideoutWrappersToTop();"><i></i>{{'misc_cancel' | translate}}</button>
                                    <button class="cancel-button" *ngIf="(selectedDays | objLen)" (click)="cancelSelectDates()"><i></i>{{'misc_cancel' | translate}}</button>
                                </div>
                                <!-- emptystate past -->
                                <!-- <ng-container *ngIf="!unavailabilityFormActive && (!(selectedDays | objLen) && selectedDay.isPast)">
                                    <div class="empty-state empty-state--no-data">{{'planning_past_availability_emptystate' | translate}}</div>
                                </ng-container> -->
                                <!-- form -->
                                <div class="slideout-panel slideout-panel--unavailability--form" *ngIf="unavailabilityFormActive">
                                    <button class="back-button" (click)="unavailabilityFormActive = false"><i></i></button>
                                    <div class="title">{{'planning_tab_availability_form_title_1' | translate}} <span>{{'planning_tab_availability_form_title_2' | translate}}</span> {{'planning_tab_availability_form_title_3' | translate}}</div>
                                    <div class="picker-wrap">
                                        <div class="left" [class.saving]="submitting">
                                            <div class="unavailability" *ngFor="let FORM of unavailabilityFORM">
                                                <div>
                                                    <span>{{'planning_tab_availability_form_from' | translate}}</span> 
                                                    <input [class.invalid]="FORM.invalid" type="text" pattern="[0-9]*" inputmode="numeric" (focus)="onFocusUnavailability()" (blur)="onBlurUnavailability()" (ngModelChange)="onChangeUnavailability()" [mask]="'Hh:m0'" [clearIfNotMatch]="true" [leadZeroDateTime]="true" placeholder="00:00" [(ngModel)]="FORM.localFrom">
                                                    <span>{{'planning_tab_availability_form_until' | translate}}</span> 
                                                    <input [class.invalid]="FORM.invalid" type="text" pattern="[0-9]*" inputmode="numeric" (focus)="onFocusUnavailability()" (blur)="onBlurUnavailability()" (ngModelChange)="onChangeUnavailability()" [mask]="'Hh:m0'" [clearIfNotMatch]="true" [leadZeroDateTime]="true" placeholder="00:00" [(ngModel)]="FORM.localUntil">
                                                </div>
                                                <div *ngIf="FORM.invalid" class="warning-msg">{{'planning_tab_availability_form_warning_msg' | translate}}</div>
                                            </div>
                                            <button *ngIf="unavailabilityFORM.length < 3" class="link" (click)="addUnavailability()">+ {{'planning_tab_availability_form_add_another_unavailability' | translate}}</button>
                                        </div>
                                        <div class="right">
                                            <div class="day-circle">
                                                <span class="top">12:00</span>
                                                <span class="right">18:00</span>
                                                <span class="bottom">00:00</span>
                                                <span class="left">06:00</span>
                                                <i class="icon-sun"></i>
                                                <i class="icon-moon"></i>
                                                <i class="sep"></i>
                                                <ng-container *ngFor="let range of unavailabilityFORM">
                                                    <div class="range" *ngIf="range.from && range.until && range.percentageOfDay" style="--pLength:{{range.percentageOfDay}};--rotate:{{range.rotateAmount}}deg"></div>
                                                </ng-container>
                                            </div>
                                        </div>
                                    </div>
                                    <button class="submit-button saving--pre" [class.saving]="!allowSubmitUnavailabilities() || submitting" (click)="submitUnavailabilities()">{{'planning_tab_availability_form_save' | translate}}</button>
                                </div>
                            </ng-container>
                        </ng-container>
                        <!-- populated -->
                        <div class="slideout-panel slideout-panel--availability--populated" *ngIf="((selectedDay.available !== undefined || selectedDay?.unavailable?.length) && !(selectedDays | objLen) && !editSelectedDayActive) || ((selectedDays | objLen) && selectedDaysStatus() && !editSelectedDayActive) && !(selectedDays | objLen)">
                            <div class="title" *ngIf="!(selectedDays | objLen)">{{'planning_tab_populated_title' | translate}}</div>
                            <div class="title" *ngIf="(selectedDays | objLen) && selectedDaysStatus() !== 'mixed'">{{'planning_tab_populated_title_multiple_days' | translate}}</div>
                            <div class="title" *ngIf="(selectedDays | objLen) && selectedDaysStatus() === 'mixed'">{{'planning_tab_populated_title_multiple_days_mixed_availability' | translate}}</div>
                            <div class="app-button app-button--pos" *ngIf="(!(selectedDays | objLen) && selectedDay.available === true) || ((selectedDays | objLen) && selectedDaysStatus() === 'pos')"><button>{{'planning_tab_populated_status_available' | translate}}</button></div>
                            <div class="app-button app-button--mixed" *ngIf="selectedDay.unavailable?.length && !(selectedDays | objLen)"><button>{{'planning_tab_populated_status_mixed' | translate}}</button></div>
                            <div class="app-button app-button--neg" *ngIf="(!(selectedDays | objLen) && selectedDay.available === false) || ((selectedDays | objLen) && selectedDaysStatus() === 'neg')"><button>{{'planning_tab_populated_status_unavailable' | translate}}</button></div>
                            <div class="not-available-times" *ngIf="selectedDay?.unavailable?.length && !(selectedDays | objLen)">
                                <span>{{'planning_tab_populated_not_available_title' | translate}}</span>
                                <span *ngFor="let time of selectedDay.unavailable">{{'planning_tab_populated_not_available_from' | translate}} {{time.from}} {{'planning_tab_populated_not_available_until' | translate}} {{time.until}}</span>
                            </div>
                            <button *ngIf="!(selectedDays | objLen) && !selectedDay.isPast" class="edit-button" (click)="editSelectedDayActive = true; slideoutWrappersToTop();"><i></i>{{'planning_tab_populated_button_edit' | translate}}</button>
                            <!-- <button *ngIf="(selectedDays | objLen)" class="edit-button" (click)="editSelectedDayActive = true"><i></i>{{'planning_tab_populated_button_edit_multiple_days' | translate}}</button> -->
                        </div>
                        <!-- emptystate past -->
                        <ng-container *ngIf="!unavailabilityFormActive && (!(selectedDays | objLen) && selectedDay.isPast)">
                            <div class="empty-state empty-state--no-data" [class.pt-1]="!((selectedDay.available === undefined && !selectedDay?.unavailable?.length && !(selectedDays | objLen)) || ((selectedDays | objLen) && !selectedDaysStatus()) || editSelectedDayActive)">{{'planning_past_availability_emptystate' | translate}}</div>
                        </ng-container>
                    </div>
                </ng-container>
            </ng-container>
            <!-- SELECT DATES -->
            <ng-container *ngIf="selectDatesActive">
                <div class="slideout-head">
                    <div><button class="cancel-select-dates" (click)="cancelSelectDates()"><span>{{'misc_cancel' | translate}}</span></button></div>
                    <div><div class="title">{{'planning_selectdates_title' | translate}} ({{selectedDays | objLen}})</div></div>
                    <div><button *ngIf="false" class="confirm-select-dates saving--pre" (click)="confirmSelectDates()" [class.saving]="!((selectedDays | objLen) >= 2)">{{'misc_confirm' | translate}}</button><!--@feedback--></div>
                </div>
                <div class="slideout-content">
                    <div class="slideout-panel slideout-panel--select-dates" *ngIf="!unavailabilityFormActive">
                        <ng-container *ngFor="let day of selectedDays | keyvalue">
                            <div class="selected-day">{{day.key | formatTs:'DD MMMM YYYY'}}</div>
                        </ng-container>
                        <ng-container *ngIf="!(selectedDays | objLen)">
                            <div class="selected-day empty">{{'planning_selectdates_emptystate' | translate}}</div>
                        </ng-container>
                        <!-- warning multiple days -->
                        <div class="warning">{{'planning_tab_availability_warning_multiple_days' | translate}}</div>
                        <button class="confirm-select-dates saving--pre" (click)="confirmSelectDates()" [class.saving]="!((selectedDays | objLen) >= 2)">{{'misc_confirm' | translate}}</button>
                    </div>
                </div>
            </ng-container>
            <!-- JOBS TO ACCEPT | JOBS ACCEPTED -->
            <ng-container *ngIf="slideoutTab === 'jobs'">
                <div class="slideout-content">
                    <div class="slideout-jobs">
                        <!-- actionsToAccept -->
                        <ng-container *ngFor="let job of selectedDay.actionsToAccept">
                            <div class="slideout-panel slideout-panel--job">
                                <app-job [isControlcenter]="isControlcenter" [job]="job" [submitting]="submitting" (info)="expandJobInfo($event)" [toAccept]="true" (accept)="acceptJob($event)" (decline)="refuseJob($event)"></app-job>
                            </div>
                        </ng-container>
                        <!-- actionsAccepted -->
                        <ng-container *ngFor="let job of selectedDay.actionsAccepted">
                            <div class="slideout-panel slideout-panel--job">
                                <app-job [isControlcenter]="isControlcenter" [job]="job" [submitting]="submitting" (info)="expandJobInfo($event)"></app-job>
                            </div>
                        </ng-container>
                        <!-- emptystate -->
                        <ng-container *ngIf="!selectedDay.actionsAccepted?.length && !selectedDay.actionsToAccept?.length">
                            <div class="empty-state">{{'planning_jobs_emptystate' | translate}}</div>
                        </ng-container>
                    </div>
                </div>
            </ng-container>
            <!-- JOBS OPEN -->
            <ng-container *ngIf="slideoutTab === 'open'">
                <div class="slideout-content">
                    <div class="slideout-jobs">
                        <!-- actionsApplied -->
                        <ng-container *ngFor="let job of selectedDay.actionsApplied">
                            <div class="slideout-panel slideout-panel--job">
                                <app-job [isControlcenter]="isControlcenter" [job]="job" [submitting]="submitting" (info)="expandJobInfo($event)" [isOpen]="true" (withdraw)="withdrawJob($event)"></app-job>
                            </div>
                        </ng-container>
                        <!-- actionsOpen -->
                        <ng-container *ngFor="let job of selectedDay.actionsOpen">
                            <div class="slideout-panel slideout-panel--job">
                                <app-job [isControlcenter]="isControlcenter" [job]="job" [submitting]="submitting" (info)="expandJobInfo($event)" [isOpen]="true" (apply)="applyJob($event)"></app-job>
                            </div>
                        </ng-container>
                        <!-- emptystate -->
                        <ng-container *ngIf="!selectedDay.actionsApplied?.length && !selectedDay.actionsOpen?.length">
                            <div class="empty-state">{{'planning_openjobs_emptystate' | translate}}</div>
                        </ng-container>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>

    <div class="slideout-backdrop" [class.active]="slideoutExpanded" (click)="slideoutExpanded = false"></div>

    <div class="gray-backdrop" [class.active]="jobDetailExpanded || legendExpanded"></div>

    <div class="job-detail" [class.active]="jobDetailExpanded">
        <button class="job-detail-back" (click)="closeJobInfo()"><i></i> {{'misc_back' | translate}}</button>
        <div class="slideout-panel" *ngIf="jobDetail">
            <app-job [isControlcenter]="isControlcenter" [job]="jobDetail" [expanded]="jobDetailExpanded" [isInfo]="true" (info)="expandJobInfo($event)"></app-job>
        </div>
    </div>

    <div class="legend" [class.active]="legendExpanded">
        <button class="legend-back" (click)="closeLegend()"><i></i> {{'misc_back' | translate}}</button>
        <div class="title"><i></i> {{'planning_legend_title' | translate}}</div>
        <div class="slideout-panel">
            <!-- <img src="/collabor8-planning/assets/img/legend.svg" alt="legend"> -->
            <img src="/collabor8-planning/assets/img/legend-{{user.LANGUAGE || 'en'}}.svg" alt="legend">
        </div>
    </div>

</div>

<!-- <pull-to-refresh [autoDismiss]="false" [disabled]="jobDetailExpanded || disablePullToRefresh || submitting"></pull-to-refresh> -->